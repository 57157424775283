import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchType(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/products/type/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    updateType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post("products/type", data)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
    deleteType(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .delete(`products/type?id=${data.id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  },
};
